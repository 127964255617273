// "use strict";
let SnazzyInfoWindow;
(function () {
  getMapScript()

  function initMap() {
    SnazzyInfoWindow = require('snazzy-info-window');
    mainThread();
  }
  window.initMap = initMap;

  function getPageLang() {
    const currentUrl = new URL(location);
    let paramLang;
    if (currentUrl.searchParams.get('lang')) {
      paramLang = currentUrl.searchParams.get('lang');
    }
    let lang = paramLang || (navigator.language || navigator.userLanguage);
    lang = lang.substring(0, 2) !== 'pl' ? 'en' : lang.substring(0, 2);
    return lang
  }

  function getMapScript() {
    const lang = getPageLang();
    const url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDMDQ6VNxeifbc1P_Dmpip-ykSATfg46F4&callback=initMap&language=' + lang;
    const script = document.createElement('script');
    script.src = url;
    document.head.append(script)
  }

  function manageLanguage(paramLang) {
    return new Promise((resolve, reject) => {
      let lang = getPageLang();
      fetch('lang/' + lang + '.json')
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  function translate(key, translations) {
    return getPropByString(key, translations) || 'Missing translation('+key+')';
    function getPropByString(propString, obj) {
      if (!propString)
        return obj;
      var prop, props = propString.split('.');
      for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];
        var candidate = obj[prop];
        if (candidate !== undefined) {
          obj = candidate;
        } else {
          break;
        }
      }
      return obj[props[i]];
    }
  }

  async function mainThread() {
    let preparation;
    let operatorId;
    let contractId;
    let mapMarkers = [];

    const currentUrl = new URL(location);

    if (currentUrl) {
      if (currentUrl.searchParams.get('operatorId')) {
        operatorId = currentUrl.searchParams.get('operatorId');
      }
      if (currentUrl.searchParams.get('contractId')) {
        contractId = currentUrl.searchParams.get('contractId');
      }
      if (currentUrl.searchParams.get('inPreparation')){
        preparation = currentUrl.searchParams.get('inPreparation');
      }
    }

    const translations = await manageLanguage();

    const portTypeTranslations = {
      "TYPE_3": "Typ 3",
      "TYPE_2": "Typ 2",
      "TYPE_1": "Typ 1",
      "CHADEMO": "CHAdeMO",
      "CEE_3": "CEE 3",
      "CARAVAN": "Caravan",
      "CSS": "CSS",
      "CCS": "CCS",
      "TESLA": "Tesla",
      "TESLA_ROADSTER": "Tesla Roadster",
      "TESLA_R": "Tesla Roadster",
      "WALL": "Wall"
    };
    const Marker = {
      infoWindowStyles: {
        borderRadius: '15px',
        border: false,
        padding: '8px',
        closeOnMapClick: true,
        showCloseButton: false,
        pointer: false,
        offset: {
          top: '-35px',
          bottom: '0',
          left: '10px'
        },
        shadow: {
          h: '0px',
          v: '20px',
          blur: '20px',
          spread: '0px',
          opacity: '1',
          color: 'rgba(0,0,0,0.1)'
        },
        wrapperClass: 'ec-tooltip-map-wrapper'
      }
    };
    const markerColor = {
      available: '#47CD12',
      occupied: '#00BAFF',
      disabled: '#F03A3A',
      preparation: '#9a9baf',
      shadow: 'rgba(0, 0, 0, 0.1)'
    };

    let hostUrl = `https://api.elo.city`;
    // if (process.env.NODE_ENV === 'development') { // Or, `process.env.NODE_ENV !== 'production'`
    //   hostUrl = `https://elocity-dev.connectmedica.com`;
    // }
    const mapUrl = `${hostUrl}/api/chargers?perPage=100${preparation ? ('&inPreparation='+preparation) : ''}${operatorId ? ('&operatorId='+operatorId) : ''}${contractId ? ('&contractId='+contractId) : ''}&page=`;
    const myLatLng = { lat: 52.1702394, lng: 19.6566836};
    const mapProp = {
      center : new google.maps.LatLng(myLatLng.lat, myLatLng.lng),
      zoom: 6,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      gestureHandling: 'cooperative'
    };
    const map = new google.maps.Map(document.getElementById("map"), mapProp);
    const startTime = new Date().getTime();

    function getAllChargersPages(mapUrl) {
      let chargersData = [];
      return new Promise((resolve, reject) => {
        let page = 1;
        // getAllSynchronize(page)
        getAllAsync(page)
          .then(resolve)
          .catch(reject);
      })

      function getAllAsync(page) {
        return new Promise((resolve, reject) => {
          getPage(page).then(dataJson => {
            chargersData.push({page: dataJson.current_page, data: dataJson.items});
            if (dataJson.pages > page) {
              aa(page+1, resolve, dataJson);
            } else {
              resolve(chargersData);
            }
          })
        })

        function aa(i, resolve, data) {
          getPage(i).then(dataJson2 => {
            chargersData.push({page: dataJson2.current_page, data: dataJson2.items});
            if (chargersData.length >= data.pages) {
              resolve(chargersData);
            }
          })
          if (i < data.pages) {
            aa(i + 1, resolve, data);
          }
        }
      }

      function getAllSynchronize(page) {
        return getPage(page).then(dataJson => {
          chargersData = [...chargersData, ...[{page: dataJson.current_page, data: dataJson.items}]];
          if (dataJson.current_page < dataJson.pages) {
            return getAllSynchronize(dataJson.current_page + 1);
          } else {
            return chargersData;
          }
        })
      }

      function getPage(page) {
        return new Promise((resolve, reject) => {
          getData(mapUrl, page)
            .then((data) => data.json())
            .then(resolve)
            .catch(reject);
        });
      }
    }



    function getChargersPageByPage(mapUrl, renderFunction) {

      return new Promise((resolve, reject) => {
        let chargersData = [];
        getAllSynchronize(1).then(
          response => {
            if (response[0]) {
              resolveAll(response, 0);
            }
            function resolveAll(promises, promiseIndex) {
              if (promises.length > promiseIndex) {
                promises[promiseIndex].then(dataJson => {
                  chargersData = [...chargersData, ...[{page: dataJson.current_page, data: dataJson.items}]];
                  renderFunction(chargersData)
                  resolveAll(promises, promiseIndex + 1);
                })
              } else {
                resolve(chargersData);
              }
            }
          }
        ).catch(reject);
      })

      function getAllSynchronize(page) {
        const combinePromises = []
        return getPage(page).then(dataJson => {
          combinePromises.push((new Promise((resolve, reject) => {
            resolve(dataJson);
          })))
          addToCombine(combinePromises, dataJson.current_page, dataJson.pages);
          return combinePromises;
        })
      }

      function addToCombine(combinePromises, currentPage, allPages) {
        if (currentPage < allPages) {
          combinePromises.push(getPage(currentPage + 1));
          addToCombine(combinePromises, currentPage + 1, allPages);
        }
      }

      function getPage(page) {
        return new Promise((resolve, reject) => {
          getData(mapUrl, page)
            .then((data) => data.json())
            .then(resolve)
            .catch(reject);
        });
      }
    }

    function getData(url = '', page = 1) {
      return fetch(url + page.toString(), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'api-key': '03336d77-d5bf-11e7-8359-0242ac120002',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
    }

    function createChargerMarker(charger) {
      let states = {
        available: 0,
        occupied: 0,
        disabled: 0,
        preparation: 0
      };
      let _totalPortsNumber = 0;
      let markerLabel = 0;
      let _limitedAccess = 0;
      let _online = false;
      let _active = false;
      for (let i = 0, len = charger.chargers.length; i < len; i++) {
        _totalPortsNumber += charger.chargers[i].ports.length;

        if (charger.chargers[i].limited_access) {
          _limitedAccess++;
        }
        if (charger.chargers[i].online) {
          _online = true;
        }
        if (charger.chargers[i].active) {
          _active = true;
        }
        for (let j = 0, len = charger.chargers[i].ports.length; j < len; j++) {
          if (charger.chargers[i].ports[j].state === 'AVAILABLE' && charger.chargers[i].ports[j].has_public_pricelist === true && charger.chargers[i].active && (charger.chargers[i].online || preparation == 1)) {
            states.available++;
          } else if (charger.chargers[i].ports[j].state === 'OCCUPIED') {
            states.occupied++;
          } else if (charger.chargers[i].ports[j].has_public_pricelist === false) {
            states.preparation++;
          } else {
            states.disabled++;
          }
        }
      }


      const r = 22.5;
      const circleWidth = 2 * Math.PI * r;

      const getPercentageNew = function(stateValue) {
        return (stateValue / _totalPortsNumber) * circleWidth;
      };

      let markerZIndex = 999;
      let _pieFillColor = '';
      let markerStatus = 'disabled';
      if ((_online || preparation == 1) && _active) {
        if (states.available > 0) {
          _pieFillColor = markerColor.available;
          markerLabel = states.available;
          markerStatus = 'available';
          markerZIndex = 999999;
        } else if (states.occupied > 0) {
          _pieFillColor = markerColor.occupied;
          markerLabel = states.occupied;
          markerStatus = 'occupied';
          markerZIndex = 99999;
        } else if (states.preparation > 0) {
          _pieFillColor = markerColor.preparation;
          markerLabel = states.preparation;
          markerStatus = 'preparation';
          markerZIndex = 9999;
        } else {
          _pieFillColor = markerColor.disabled;
          markerLabel = _totalPortsNumber;
          markerStatus = 'disabled';
        }
      } else {
        _pieFillColor = markerColor.disabled;
        markerLabel = _totalPortsNumber;
        markerStatus = 'disabled';
        states.available = 0;
        states.occupied = 0;
      }

      const limitedAccessHtml =
        `<path id="Path_742" data-name="Path 742" d="M7.692.11,28.6-.257l5.929,5.929L3.156,6.227Z" transform="translate(-10 17) rotate(-45)" fill="rgba(0,0,0,0.2)"/>` +
        `<path id="Path_740" data-name="Path 740" d="M0,0,36.411-.221l-.054,6.357L0,6Z" transform="translate(0 25) rotate(-45)" fill="rgba(0,0,0,0.2)"/>` +
        `<path id="Path_741" data-name="Path 741" d="M1.912.172,35.023-.353,31.31,5.207,0,6Z" transform="translate(8 35) rotate(-45)" fill="rgba(0,0,0,0.2)"/>`;
      const svgString = `` +
        `<svg xmlns="http://www.w3.org/2000/svg" width="49" height="58" viewBox="0 0 49 58" style="${markerStatus === 'disabled' ? 'transform: scale(0.7); z-index: 0;' : ''}">` +
        `<g id="Group_51" data-name="Group 51" transform="translate(-48 -706)">` +
        `<g id="Ellipse_66" data-name="Ellipse 66" transform="translate(48 706)" fill="#fff" >` +
        `<circle cx="24.5" cy="24.5" r="24.5" stroke="none" stroke-width="4"/>` +
        `<circle id="grayRing" cx="24.5" cy="24.5" r="22.5" fill="transparent" stroke-width="4" stroke="${markerColor.disabled}"/>` +
        `<circle id="greenRing" cx="24.5" cy="24.5" r="22.5" fill="transparent" stroke-width="4" stroke="${markerColor.available}"` +
        ` stroke-dashoffset="${circleWidth / 5}"` +
        ` stroke-dasharray="` + getPercentageNew(states.available) + ` ` + (circleWidth - getPercentageNew(states.available)) + `"` +
        `/>` +
        `<circle id="redRing" cx="24.5" cy="24.5" r="22.5" fill="transparent" stroke-width="4" stroke="${markerColor.occupied}"` +
        ` stroke-dashoffset="` + (circleWidth - getPercentageNew(states.available) + (circleWidth / 5)) + `"` +
        ` stroke-dasharray="` + getPercentageNew(states.occupied) + ` ` + (circleWidth - getPercentageNew(states.occupied)) + `"` +
        `/>` +
        `<circle id="prepareRing" cx="24.5" cy="24.5" r="22.5" fill="transparent" stroke-width="4" stroke="${markerColor.preparation}"` +
        ` stroke-dashoffset="` + (circleWidth - getPercentageNew(states.available) - getPercentageNew(states.occupied) + (circleWidth / 5)) + `"` +
        ` stroke-dasharray="` + getPercentageNew(states.preparation) + ` ` + (circleWidth - getPercentageNew(states.preparation)) + `"` +
        `/>` +
        `</g>` +
        `<g id="Union_1" data-name="Union 1" transform="translate(-1266.001 16368)" fill="${_pieFillColor}">` +
        `<path d="M 1339.001098632813 -15606.13671875 L 1332.34375 -15618.70703125 L 1332.1640625 -15619.046875 L 1331.803588867188 -15619.1787109375 C 1328.087524414063 -15620.537109375 1324.905151367188 -15622.9677734375 1322.6005859375 -15626.2080078125 C 1320.245971679688 -15629.5185546875 1319.001342773438 -15633.4228515625 1319.001342773438 -15637.5 C 1319.001342773438 -15640.1328125 1319.516723632813 -15642.6865234375 1320.532958984375 -15645.08984375 C 1321.514892578125 -15647.412109375 1322.920776367188 -15649.498046875 1324.711547851563 -15651.2890625 C 1326.50244140625 -15653.080078125 1328.587524414063 -15654.486328125 1330.909057617188 -15655.46875 C 1333.312255859375 -15656.4853515625 1335.865600585938 -15657.0009765625 1338.498291015625 -15657.0009765625 C 1341.131958007813 -15657.0009765625 1343.686157226563 -15656.4853515625 1346.08984375 -15655.46875 C 1348.411865234375 -15654.486328125 1350.497436523438 -15653.080078125 1352.28857421875 -15651.2890625 C 1354.07958984375 -15649.498046875 1355.485595703125 -15647.412109375 1356.467529296875 -15645.08984375 C 1357.48388671875 -15642.6865234375 1357.999267578125 -15640.1328125 1357.999267578125 -15637.5 C 1357.999267578125 -15633.6533203125 1356.880859375 -15629.9326171875 1354.765014648438 -15626.740234375 C 1352.700805664063 -15623.6259765625 1349.810302734375 -15621.1806640625 1346.406127929688 -15619.6689453125 L 1346.089965820313 -15619.5283203125 L 1345.92822265625 -15619.22265625 L 1339.001098632813 -15606.13671875 Z" stroke="none"/>` +
        `<path d="M 1339.0009765625 -15608.2744140625 L 1345.04443359375 -15619.6904296875 L 1345.368041992188 -15620.3017578125 L 1346.000122070313 -15620.5830078125 C 1349.230102539063 -15622.017578125 1351.97265625 -15624.337890625 1353.931396484375 -15627.29296875 C 1355.9384765625 -15630.3203125 1356.999267578125 -15633.849609375 1356.999267578125 -15637.5 C 1356.999267578125 -15642.44140625 1355.0751953125 -15647.087890625 1351.581420898438 -15650.58203125 C 1348.087280273438 -15654.076171875 1343.44091796875 -15656.0009765625 1338.498291015625 -15656.0009765625 C 1333.557373046875 -15656.0009765625 1328.912353515625 -15654.076171875 1325.418701171875 -15650.58203125 C 1321.92529296875 -15647.087890625 1320.001342773438 -15642.44140625 1320.001342773438 -15637.5 C 1320.001342773438 -15633.6318359375 1321.181884765625 -15629.927734375 1323.415405273438 -15626.7880859375 C 1325.602294921875 -15623.712890625 1328.62158203125 -15621.4072265625 1332.14697265625 -15620.1171875 L 1332.8681640625 -15619.853515625 L 1333.2275390625 -15619.17578125 L 1339.0009765625 -15608.2744140625 M 1339.001342773438 -15604 L 1331.460083007813 -15618.2392578125 C 1323.606689453125 -15621.111328125 1318.001342773438 -15628.6513671875 1318.001342773438 -15637.5 C 1318.001342773438 -15648.8212890625 1327.177001953125 -15658.0009765625 1338.498291015625 -15658.0009765625 C 1349.823486328125 -15658.0009765625 1358.999267578125 -15648.8212890625 1358.999267578125 -15637.5 C 1358.999267578125 -15629.1376953125 1353.992919921875 -15621.9443359375 1346.81201171875 -15618.7548828125 L 1339.001342773438 -15604 Z" stroke="none" fill="#fff"/>` +
        `</g>` +
        `<g id="Union_2" data-name="Union 2" transform="translate(58 716)">` +
        `<circle id="Ellipse_67" data-name="Ellipse 67" cx="14.5" cy="14.5" r="14.5"  fill="${_pieFillColor}"/>` +
        (_limitedAccess > 0 ? limitedAccessHtml : ``) +
        `<text xmlns="http://www.w3.org/2000/svg" id="Ellipse_67" dominant-baseline="middle" text-anchor="middle" data-name="Ellipse 67" x="30%" y="28%" fill="#fff" style="color: rgb(255, 255, 255);font-size: 14px;font-weight: 900;font-family: Quicksand,sans-serif;">${markerLabel.toString()}</text>` +
        `</g>` +
        `</g>` +
        `</svg>`;

      const gmMarker = new google.maps.Marker({
        position: new google.maps.LatLng(charger.lat, charger.lng),
        map: map,
        optimized: true,
        zIndex: markerZIndex,
        icon: {
          url: 'data:image/svg+xml;base64,' + window.btoa(svgString),
        },
      });

      gmMarker['_totalPorts'] = _totalPortsNumber;
      gmMarker['_availablePorts'] = states.available;
      gmMarker['_occupiedPorts'] = states.occupied;
      gmMarker['_inPreparationPorts'] = states.preparation;
      gmMarker['_disabledPorts'] = states.disabled;
      gmMarker['_limitedAccess'] = _limitedAccess;

      const infoWindow = new SnazzyInfoWindow(
        Object.assign({
            marker: gmMarker,
            content: createMarkerTooltip(charger),
            closeWhenOthersOpen: true
          },
          Marker.infoWindowStyles
        )
      );
      google.maps.event.addListener(gmMarker, 'click', function () {
        infoWindow.open(map, gmMarker);
        map['_infoWindow'] = infoWindow;
      }.bind(this));

      return {
        infoWindow: infoWindow,
        gmMarker: gmMarker
      }
    }

    function createMarkerTooltip(charger) {

      let content = '<div class="marker-tooltip">';

      charger.chargers.forEach((item) => {
        content +=
          '<div class="section section--border-btn">' +
          '<div class="address">' +
          '<span>' + (item.address.street ? item.address.street : '') + ' ' + '</span>' +
          '<span>' + (item.address.street_apartment_number ? (item.address.street_apartment_number + ',') : '') + '</span><br>' +
          '<span>' + (item.address.postal_code ? item.address.postal_code : '') + '</span> ' +
          '<span>' + (item.address.city ? item.address.city : '') + '</span>' +
          '</div>' +
          `<p class="charger-name">${item.name}</p>`;

        let _ports = [];
        item.ports.forEach((port) => {
          port.state = (!port.has_public_pricelist ? "PREPARATION" : port.state)
          if (!_ports.filter(_port => _port['type'] === port.connector_type).length) {
            _ports.push({
              type: port.connector_type,
              state: [port.state]
            })
          } else {
            _ports.filter(item => item.type === port.connector_type)[0].state.push(port.state);
          }
        });

        _ports.forEach((port) => {
          content +=
            '<div class="ports">' +
            '<span class="port-type">' + portTypeTranslations[port.type] + ':</span>';
          content += '<div class="state">';
          port.state.forEach((state) => {
            if (!(item.online || preparation == 1) || !item.active) {
              content +=
                '<div class="status DISABLED"></div>';
            } else {
              content +=
                '<div class="status ' + state + '"></div>';
            }
          });
          content += '</div>';
          content += '</div>';
        });
        content += '</div>';
      });
      content += '</div>';

      return content;
    }

    function groupChargers(chargers) {
      const uniqueChargers = chargers.filter(function (outerEl, outerIndex, outerArr) {
        return outerArr.filter((innerEl, innerIndex, innerArr) => {
          return (innerEl.address.lng === outerEl.address.lng && innerEl.address.lat === outerEl.address.lat && outerIndex !== innerIndex);
        }).length === 0;
      });

      const duplicated = chargers.filter(function (outerEl, outerIndex, outerArr) {
        return outerArr.filter((innerEl, innerIndex, innerArr) => {
          return (innerEl.address.lng === outerEl.address.lng && innerEl.address.lat === outerEl.address.lat && outerIndex !== innerIndex);
        }).length > 0;
      });

      const _arr = [];
      duplicated.forEach((charger) => {
        if (_arr.filter(item => item['lat'] === charger.address.lat && item['lng'] === charger.address.lng).length === 0) {
          _arr.push(
            {
              status: calculateChargerStatus(
                duplicated.filter(
                  innerItem => innerItem.address.lat === charger.address.lat && innerItem.address.lng === charger.address.lng
                )
              ),
              lat: charger.address.lat,
              lng: charger.address.lng,
              chargers: duplicated.filter(
                innerItem => innerItem.address.lat === charger.address.lat && innerItem.address.lng === charger.address.lng
              )
            }
          );
        }
      });

      uniqueChargers.forEach((item) => {
        _arr.push({
          status: calculateChargerStatus([item]),
          lat: item.address.lat,
          lng: item.address.lng,
          chargers: [item]
        });
      });

      return _arr;
    }

    function calculateChargerStatus(chargers) {
      if (chargers.filter((charger) => {
        return charger.ports.filter(port => port.state === 'AVAILABLE').length > 0;
      }).length > 0) {
        return 'AVAILABLE';
      } else if (chargers.filter((charger) => {
        return charger.ports.filter(port => port.state === 'OCCUPIED').length > 0;
      }).length > 0) {
        return 'OCCUPIED';
      } else if (chargers.filter((charger) => {
        return charger.ports.filter(port => !port.has_public_pricelist).length > 0;
      }).length > 0 && preparation == 1) {
        return 'PREPARATION';
      }
      else {
        return 'DISABLED';
      }
    }

    function clearMarkers(markers) {
        try {
          markers.forEach((marker, index, array) => {
            marker.gmMarker.setMap(null);
            google.maps.event.clearInstanceListeners(marker.gmMarker);
            marker.infoWindow.destroy()
          })
        } catch (e) {
          console.error('Removing markers error');
          console.error(e);
        }
    }

    function renderChargers(chargers) {
      const markers = [];
      const _groupedChargers = [];
      const _duplicatedGroups = [];

      const _chargers = [];
      chargers.forEach((charger) => {
        if (!_groupedChargers.find((element) => {
          return element.chargerGroup.lat === charger.lat && element.chargerGroup.lng === charger.lng;
        })) {
          _chargers.push(charger);
        } else {
          _duplicatedGroups.push(charger);
        }
      });

      _chargers.forEach((charger) => {
        const googleMarker = createChargerMarker(charger, map);
        markers.push(googleMarker);
        _groupedChargers.push(Object.assign({}, {chargerGroup: charger, googleMarker: googleMarker}));
      });

      if (_duplicatedGroups.length) {
        _duplicatedGroups.forEach((duplicatedGroup) => {
          const sourceElement = _groupedChargers.find((element) => {
            return element.chargerGroup.lat === duplicatedGroup.lat && element.chargerGroup.lng === duplicatedGroup.lng;
          });

          sourceElement.googleMarker.gmMarker.setMap(null);

          if (sourceElement.chargerGroup.status === 'AVAILABLE' || duplicatedGroup.status === 'AVAILABLE') {
            sourceElement.chargerGroup.status = 'AVAILABLE';
          } else if (sourceElement.chargerGroup.status === 'OCCUPIED' || duplicatedGroup.status === 'OCCUPIED') {
            sourceElement.chargerGroup.status = 'OCCUPIED';
          } else {
            sourceElement.chargerGroup.status = 'DISABLED';
          }
          sourceElement.chargerGroup.chargers = sourceElement.chargerGroup.chargers.concat(duplicatedGroup.chargers);
          const chargerMarker = createChargerMarker(sourceElement.chargerGroup, map);
          markers.push(chargerMarker);
        });
      }
      clearMarkers(mapMarkers);
      mapMarkers = markers;
    }

    function renderFunction(pagerChargers) {
      pagerChargers = pagerChargers.map(b => {
        return b.data;
      });
      const chargerGrouped = groupChargers(pagerChargers.flat());
      renderChargers(chargerGrouped);
    }

    getChargersPageByPage(mapUrl, renderFunction)
      .then(pagerChargers => {
        // document.querySelectorAll('.backdrop').forEach(a => a.remove());
        document.querySelectorAll('.loader-bar-top').forEach(a => a.remove());
        // console.log('time: ' + (new Date().getTime() - startTime) * 0.001 + 's')
      })
      .catch(error => {
        console.error(error);
      })

    const legend = document.createElement('div');
    legend.setAttribute('id','legend');
    const content = [];
    content.push('<div class="legend">');
    content.push('<div class="color green"><i class="dot"></i>' + translate('Charger.Status.Available', translations) + '</div>');
    content.push('<div class="color blue"><i class="dot"></i>' + translate('Charger.Status.Occupied', translations) + '</div>');
    content.push('<div class="color red"><i class="dot"></i>' + translate('Charger.Status.Disabled', translations) + '</div>');
    if (preparation == 1){
      content.push('<div class="color grey"><i class="dot"></i>' + translate('Charger.Status.Preparation', translations) + '</div>');
    }
    content.push('<div class="limited-access"><div class="limited-icon-wrapper"><span class="limited-icon"></span><span class="limited-icon"></span><span class="limited-icon"></span></div>' + translate('Charger.LimitedAccess', translations) + '</div>');
    content.push('</div>');
    legend.innerHTML = content.join('')
    document.getElementById('map').append(legend);
  }
})()

